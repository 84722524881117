import { Experience } from '../../components/Experience';
import { Link } from '../../components/Link';
import { LeftColumn, RightColumn, Row } from '../../components/Row';
import {
  TaskContent, TaskDate, TaskList, TaskSubTitle, TaskTitle, TaskWrapper,
} from '../../components/Task';

export function StarshellTasks() {
  return (
    <Row
      id="starshell"
    >
      <LeftColumn>
        <Experience
          title="스타셸 스튜디오"
          caption="Frontend Developer / Engineering Manager"
          date="2018.09 ~ 2021.07"
        />
      </LeftColumn>
      <RightColumn>
        <TaskWrapper>
          <TaskTitle>
            완전원격근무 체제 6인 교차기능팀 운영 및 관리
          </TaskTitle>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            스타셸의 개발팀은 웹 서비스 개발팀 / 앱 서비스 개발팀으로 분할되어, 각각 6인의 전문가로 구성되어 있습니다.
            <br />
            스타셸은 완전원격근무 체제를 운영하기 때문에
            각각의 구성원들이 직접 만나지 않더라도 오프라인 환경에서 개발하는 타 회사의 개발팀과 동일하거나
            더 나은 퍼포먼스를 발휘하기 위해 여러가지 제도적 장치를 만들어 운영 / 관리하고 있습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '플래닝 포커를 비롯한 효율적인 스케줄 매니징 기법을 도입',
              '매일 아침 9시에 시작하는 \'데일리 미팅\' 체계 도입 및 운영을',
              '지속적인 칸반 업데이트 및 리마인드를 이용한 팀원 일정 관리',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            사내 개발자 멘토링
          </TaskTitle>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            스타셸 내부에서 진행하는 &apos;렛츠기릿&apos;에서 짧은 발표 및 프론트엔드 개발자 분들과의 멘토링을 진행하였습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '사내 개발자 행사 \'렛츠기릿\'에서 15분 분량의 발표 격주마다 진행',
              '프론트엔드 코드리뷰 문화를 도입',
              '새로 입사하신 개발자 분들을 위한 프론트엔드팀 가이드라인을 만들고 배포',
              '사내 위키에 꾸준한 기술 문서를 추가',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            국민은행 순회점검 시스템 개발
          </TaskTitle>
          <TaskDate>
            2019년 3월 ~ 2019년 7월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            국민은행의 각 지점별 시설 관리자들이 해당 지점의 점검 내역(에어컨, 미세먼지, 환풍구 등)을 기재할 수 있습니다.
            <br />
            이렇게 올라온 점검 내역들은 시스템 관리자가 하나로 모아서 관리할 수 있으며
            작성 일시, 지점명 등으로 데이터를 검색할 수 있고 엑셀로 직접 내려받을 수도 있습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '지점관리자가 해당 지점의 시설 상태를 입력하고 총관리자에게 리포트를 제출 할 수 있는 기능 개발',
              '총관리자는 지점관리자가 올린 보고서를 볼 수 있고 각 지점별 시설 점검 상태를 액셀로 다운 받아 볼 수 있는 기능 개발',
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'Nuxt.js',
              'Vuex',
              'Element UI',
              'Chart.js',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            현대이노션 눈금 백오피스 개발
          </TaskTitle>
          <TaskDate>
            2020년 4월 ~ 2020년 6월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            현대 이노션의 ‘눈금’은 해당 서비스를 이용하는 부모님이 자녀에게 오늘 제공한 식사, 아이의 체중, 나이 등을 입력하면
            매칭 된 전문가가 아이의 생활 습관 케어, 식단 등을 정해주는 아이를 위한 건강한 식단 컨설팅 서비스입니다.
            백오피스에서는 부모님이 작성하신 글을 열람할 수 있으며, 전문가 계정으로 로그인하면 해당 부모님에게 별도의 코멘트 및 일주일간의 식단을 구성해줄 수 있습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '부모님이 입력한 식단을 식판 이미지로 보여주고 음식의 열량 및 영양 정보를 보여주는 기능을 개발',
              '부모님과 영양사가 1:1로 대화 할 수 있는 채팅 기능을 연동',
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'Nuxt.js',
              'Vuex',
              'Vant UI',
            ]}
          />
          <TaskSubTitle>
            News
          </TaskSubTitle>
          <TaskContent>
            <Link href="https://www.etnews.com/20191023000042">
              전자신문 - 이노션 사내 스타트업 1호 &apos;눈금&apos;, 창업으로 새 출발
            </Link>
          </TaskContent>
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            프레제뉴 생일 선물 펀딩 서비스 개발 및 총괄
          </TaskTitle>
          <TaskDate>
            2021년 4월 ~ 2021년 7월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            누군가에게 특별한 경험을 선물하기 위한 자금을 모으는 크라우드 펀딩 서비스.
            친구가 받고 싶어하는 선물을 등록하면 선물 펀딩 페이지가 개설 됩니다.
            이렇게 개설 된 페이지를 펀딩에 참여하는 친구들에게 공유하여 모금을 완료하면 프레제뉴가 직접 선물을 해당 친구에게 전달해줍니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '검색엔진 최적화',
              '스마트폰 및 PC 크로스 플랫폼 최적화를 위한 반응형 웹을 개발',
              '카카오톡, 구글 소셜 로그인 연동',
              '채널톡 연동',
              'Animate.css를 이용한 재미있는 트랜지션 및 이펙트 제작',
              '아임포트를 이용한 펀딩 금액 모금 결제 서비스 연동',
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'Nuxt.js',
              'Vuex',
              'Vant UI',
              '아임포트 (결제 연동 솔루션)',
              'Animate.css',
              '채널톡',
              'OAuth',
              'SEO',
            ]}
          />
          <TaskSubTitle>
            Link
          </TaskSubTitle>
          <TaskContent>
            <Link href="https://presenu.com/">
              프레제뉴 | 모아서 선물하는 펀딩 플랫폼
            </Link>
          </TaskContent>
        </TaskWrapper>
      </RightColumn>
    </Row>
  );
}
