import confetti from 'canvas-confetti';
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { UseConfetti } from './useConfetti.types';

export const useConfetti = ({
  element,
  duration,
  interval,
  effectCallback,
}: UseConfetti) => {
  const confettiInstance = useRef<confetti.CreateTypes>();
  const endTime = useMemo(() => Date.now() + duration, [duration]);

  const showEffect = useCallback(() => {
    if (confettiInstance.current && effectCallback) {
      const options = effectCallback();

      options.forEach((option) => {
        confettiInstance.current?.(option);
      });
    }
  }, [
    effectCallback,
    confettiInstance,
    endTime,
  ]);

  useEffect(() => {
    const intervalNumber = window.setInterval(() => {
      showEffect();

      if (Date.now() > endTime) {
        window.clearInterval(intervalNumber);
      }
    }, interval);

    return () => {
      window.clearInterval(intervalNumber);
    };
  }, []);

  useEffect(() => {
    if (confettiInstance.current === undefined) {
      confettiInstance.current = confetti.create(element, { resize: true, useWorker: true });
    }
  }, [
    element,
  ]);
};
