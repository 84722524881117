import {
  PropsWithChildren, createContext, useMemo, useState,
} from 'react';
import { FloatingMailButtonContextArguments } from './FloatingMailButtonProvider.types';

export const FloatingMailButtonContext = createContext<
  FloatingMailButtonContextArguments | undefined
>(undefined);

export function FloatingMailButtonProvider({
  children,
}: PropsWithChildren) {
  const [isShow, setIsShow] = useState(false);
  const values = useMemo(() => {
    return {
      isShow,
      setIsShow,
    };
  }, [
    isShow,
    setIsShow,
  ]);

  return (
    <FloatingMailButtonContext.Provider
      value={values}
    >
      { children }
    </FloatingMailButtonContext.Provider>
  );
}
