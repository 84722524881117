import { Dot } from '../Dot';
import { TaskTitleProps } from './TaskTitle.types';

export function TaskTitle({
  children,
}: TaskTitleProps) {
  return (
    <h5
      className="TaskTitle"
      style={{
        fontSize: '1.6rem',
        padding: '0 0 1rem',
      }}
    >
      { children }
      <Dot />
    </h5>
  );
}
