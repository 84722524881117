import { PropsWithChildren } from 'react';

export function Title({
  children,
}: PropsWithChildren) {
  return (
    <h1
      className="Title"
      style={{
        margin: '0.67em 0',
        fontSize: '5em',
      }}
    >
      { children }
    </h1>
  );
}
