import { useContext } from 'react';
import { FloatingMailButtonContext } from './FloatingMailButtonProvider';

export const useFloatingMailButton = () => {
  const context = useContext(FloatingMailButtonContext);

  if (context === undefined) throw new Error('FloatingMailButtonContext is undefined.');

  return context;
};
