import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function ExperienceCaption({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="ExperienceCaption"
      css={css`
        font-size: 16px;
        margin: 0;
        color: #222222;
      `}
    >
      { children }
    </div>
  );
}
