import { TaskDateProps } from './TaskDate.types';

export function TaskDate({
  children,
}: TaskDateProps) {
  return (
    <div
      className="Task__Date"
      style={{
        fontSize: 16,
        wordBreak: 'keep-all',
        margin: '0 0 1.5rem 0',
      }}
    >
      { children }
    </div>
  );
}
