import { css } from '@emotion/react';
import { TaskListItemProps } from './TaskListItem.types';
import { PRIMARY_COLOR_01 } from '../../constants/colors';

export function TaskListItem({
  children,
}: TaskListItemProps) {
  return (
    <li
      className="TaskListItem"
      css={css`
        position: relative;
        padding: .2rem 0 .2rem 1rem;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 9px;
          transform: translate(50%, 50%);
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: ${PRIMARY_COLOR_01};
        }
      `}
    >
      { children }
    </li>
  );
}
