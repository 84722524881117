import { TaskContentProps } from './TaskContent.types';

export function TaskContent({
  children,
}: TaskContentProps) {
  return (
    <p
      className="TaskContent"
      style={{
        margin: '0 0 1rem',
        fontSize: '1.1rem',
        lineHeight: 1.4,
        color: '#333',
      }}
    >
      { children }
    </p>
  );
}
