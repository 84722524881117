import { css } from '@emotion/react';
import { TaskListProps } from './TaskList.types';
import { TaskListItem } from './TaskListItem';

export function TaskList({
  items,
}: TaskListProps) {
  return (
    <ul
      className="TaskList"
      css={css`
        font-size: 1.1rem;
        margin: 0 0 1rem;
      `}
    >
      {
        items.map((item) => {
          return (
            <TaskListItem
              key={Math.random()}
            >
              { item }
            </TaskListItem>
          );
        })
      }
    </ul>
  );
}
