import { css } from '@emotion/react';
import { useCallback } from 'react';
import { ScrollDetector } from '../../components/ScrollDetector';
import { useFloatingMailButton } from './useFloatingMailButton';

export function FloatingMailButtonTrigger() {
  const { setIsShow } = useFloatingMailButton();

  const handleDetected = useCallback(() => {
    setIsShow(true);
  }, []);

  return (
    <div
      className="FloatingMailButtonTrigger"
      css={css`
        width: 100%;
        pointer-events: none;
      `}
    >
      <ScrollDetector
        threshold={300}
        onDetect={handleDetected}
      />
    </div>
  );
}
