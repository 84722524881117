import { Experience } from '../../components/Experience';
import { Link } from '../../components/Link';
import { LeftColumn, Row, RightColumn } from '../../components/Row';
import {
  TaskTitle, TaskContent, TaskList, TaskSubTitle, TaskWrapper, TaskDate,
} from '../../components/Task';

export function PixoTasks() {
  return (
    <Row
      id="pixo"
    >
      <LeftColumn>
        <Experience
          title="픽소 (PIXO)"
          caption="Frontend Developer"
          date="2022.06 ~ 현재"
        />
      </LeftColumn>
      <RightColumn>
        <TaskWrapper>
          <TaskTitle>
            Logomakershop BIZ
          </TaskTitle>
          <TaskDate>
            2023년 1월 ~ 현재
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            비즈니스 및 개인브랜딩이 필요한 모든 분들을 위해 전문 디자이너 수준의 고퀄리티 로고 제작을 돕는 글로벌 서비스입니다.
            <br />
            <Link
              href="https://logomakershop.com"
              target="_blank"
            >
              Logomakershop 둘러보기
            </Link>
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              <span>
                프로젝트의 전체적인 아키텍처 설계
              </span>,
              <span>
                공용 컴포넌트 패키지 설계 및 개발
              </span>,
              <span>
                디자인 편집 엔진을 설계하고 구현
              </span>,
              <span>
                Lerna를 활용한 Monorepo 아키텍처 설계
              </span>,
              <span>
                FastSpring 결제 모듈 연동
              </span>,
              <span>
                Contentful을 활용한 로고 큐레이션 페이지 API 및 UI 개발
              </span>,
              <span>
                검색엔진 최적화
              </span>,
              <Link href="https://univdev.page/posts/headless-browser-snapshot/">
                디자인의 결과물 추출을 위한 Snapshot 로직 개발
              </Link>,
              <Link href="https://univdev.page/posts/vercel-cicd/">
                Vercel + Next.js + Github CI / CD 연동
              </Link>,
              <Link href="https://univdev.page/posts/good-taxonomy-architecture/">
                Amplitude Taxonomy 연동
              </Link>,
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'React',
              'Next.js',
              'Typescript',
              'Github CI/CD',
              'Vercel',
              'Fastspring',
              'Lerna',
              'Material UI',
              'Contentful',
              'SEO',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            Team Blog TF 1기
          </TaskTitle>
          <TaskDate>
            2023년 5월 ~ 현재
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            채용 브랜딩 및 구성원들의 기술 공유를 위한 회사 내 기술 블로그 구축 TF 1기로 참여하였습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              <Link href="https://univdev.page/posts/new-post-tech-blog-pixo/">
                기술 블로그에 업로드 할 포스트들의 작성 규칙을 정의하였습니다.
              </Link>,
              <Link href="https://medium.com/pixo-co/%EA%B5%AC%EB%8F%85%EB%A5%A0%EC%9D%84-%EB%86%92%EC%97%AC%EB%9D%BC-%EC%9B%B9%EB%B7%B0%EB%A5%BC-%EC%9D%B4%EC%9A%A9%ED%95%9C-%EA%B5%AC%EB%8F%85-%ED%99%94%EB%A9%B4-%EA%B0%9C%EB%B0%9C-%EC%9D%BC%EC%A7%80-10ab60427493">
                새롭게 단장 된 기술 블로그의 첫 게시글을 포스트 하였습니다.
              </Link>,
            ]}
          />
        </TaskWrapper>
      </RightColumn>
    </Row>
  );
}
