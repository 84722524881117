import { PropsWithChildren } from 'react';

export function Container({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="Container"
      style={{
        margin: 'auto',
        maxWidth: 860,
        width: '100%',
      }}
    >
      { children }
    </div>
  );
}
