import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function OtherContentWrapper({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="OtherContent"
      css={css`
        font-size: 1.1rem;
        padding: 1.5rem 0 0;
        line-height: 1.4;
      `}
    >
      { children }
    </div>
  );
}
