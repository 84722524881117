import { css } from '@emotion/react';
import { useCallback } from 'react';
import clsx from 'clsx';
import { MdOutlineEmail } from 'react-icons/md';
import { PRIMARY_COLOR_01 } from '../../constants/colors';
import { MailButtonProps } from './MailButton.types';

export function MailButton({
  className,
  style,
}: MailButtonProps) {
  const handleClick = useCallback(() => {
    const emailAddress = process.env.REACT_APP_EMAIL_ADDRESS;

    window.open(`mailto:${emailAddress}`);
  }, []);

  return (
    <button
      className={clsx(
        'MailButton',
        className,
      )}
      aria-label="이메일 보내기"
      type="button"
      style={{
        ...style,
      }}
      onClick={handleClick}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 84px;
        height: 84px;
        border: none;
        background-color: ${PRIMARY_COLOR_01};
        transform: scale(1);
        transition: transform .2s;
        font-size: 1.8rem;
        color: white;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      `}
    >
      <MdOutlineEmail />
    </button>
  );
}
