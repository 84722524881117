import clsx from 'clsx';
import { css } from '@emotion/react';
import { RightColumnProps } from './RightColumn.types';

export function RightColumn({
  children,
  className,
  style,
}: RightColumnProps) {
  return (
    <div
      className={clsx(
        'Row__RightColumn',
        className,
      )}
      style={{
        ...style,
      }}
      css={css`
        flex: 1;
        @media screen and (max-width: 960px) {
          flex: auto;
          width: 100%;
        }
      `}
    >
      { children }
    </div>
  );
}
