import { OtherProps } from './Other.types';
import { OtherCaption } from './OtherCaption';
import { OtherContentWrapper } from './OtherContentWrapper';
import { OtherDate } from './OtherDate';
import { OtherTitle } from './OtherTitle';
import { OtherWrapper } from './OtherWrapper';

export function Other({
  title,
  caption,
  date,
  children,
}: OtherProps) {
  return (
    <OtherWrapper>
      <OtherTitle>
        { title }
      </OtherTitle>
      {
        caption
          && (
          <OtherCaption>
            { caption }
          </OtherCaption>
          )
      }
      <OtherDate>
        { date }
      </OtherDate>
      <OtherContentWrapper>
        { children }
      </OtherContentWrapper>
    </OtherWrapper>
  );
}
