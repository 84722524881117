import { Experience } from '../../components/Experience';
import { Link } from '../../components/Link';
import { LeftColumn, RightColumn, Row } from '../../components/Row';
import {
  TaskContent,
  TaskDate,
  TaskList,
  TaskSubTitle,
  TaskTitle, TaskWrapper,
} from '../../components/Task';
import { TaskBold } from '../../components/Task/TaskBold';

export function DangamTasks() {
  return (
    <Row
      id="dangam"
    >
      <LeftColumn>
        <Experience
          title="단감소프트"
          caption="Frontend Developer"
          date="2021.08 ~ 2022.07"
        />
      </LeftColumn>
      <RightColumn>
        <TaskWrapper>
          <TaskTitle>
            반려인들의 커뮤니티 &apos;구르미랑 반모&apos; 관리자 CMS 리뉴얼
          </TaskTitle>
          <TaskDate>
            2021년 08월 ~ 2022년 01월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            반려 동식물을 키우는 유저들이 서로 정보를 공유하며 자신의 반려 동식물을 자랑하는 커뮤니티입니다.
            게시물 관리, 회원 관리와 같은 전반적인 관리 기능을 개발했습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              '레거시 코드 리팩토링',
              '프로젝트 아키텍처 재설계 및 구현',
              '불량 사용자 신고 및 제재 기능, 게시물 관리, 사용자 권한 관리 등 기능 전체 개발',
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'Nuxt.js',
              'Vuex',
              'Vant UI',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            삼성 퇴직 임원들의 커뮤니티, &apos;성우회 및 성대회&apos; 하이브리드 앱 개발
          </TaskTitle>
          <TaskDate>
            2022년 02월 ~ 2022년 03월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            삼성의 대표단 및 임직원들의 회원 명부를 관리할 수 있는 삼성그룹 내 회원 전용 어플리케이션을 개발했습니다.
          </TaskContent>
          <TaskList
            items={[
              '대표단 및 임직원의 연락처 공유',
              '동호회 주요 일정 관리 및 공지사항 확인',
              '사전 승인받은 회원 전용 로그인 기능',
              '자동 로그아웃 기능',
            ]}
          />
          <TaskSubTitle>
            The Unique point
          </TaskSubTitle>
          <TaskContent>
            &apos;SDK가 설치 된 휴대폰이 있어도 관리자가 공유한 특수한 링크로 앱을 실행하지 않으면 앱이 자동으로 꺼지게 만들어달라&apos;는
            다소 평범하지 않은 요구사항을 Firebase의 Deeplink를 활용하여 해결한 다소 특이한 프로젝트였습니다.
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskBold>사용자 어플리케이션</TaskBold>
          <TaskList
            items={[
              'Nuxt와 React Native를 활용한 하이브리드 앱 개발',
              'React Native를 활용하여 앱 빌드 및 Google playstore 배포',
              'CMS를 통해 관리자가 생성한 Deeplink로 실행하지 않으면 앱이 꺼지는 보안 기능',
            ]}
          />
          <TaskBold>CMS</TaskBold>
          <TaskList
            items={[
              '성우회 및 성대회 구성원을 책자로 출간할 수 있도록 PDF 형태로 출력하는 기능 개발',
              '어플리케이션 데이터 CRUD 기능 개발 (행사 일정, 공지사항 등)',
              'Firebase Deeplink 자동 생성 기능 연동',
            ]}
          />
          <TaskSubTitle>
            Skills
          </TaskSubTitle>
          <TaskList
            items={[
              'React Native',
              'Firebase',
              'Nuxt.js',
              'Vuex',
              'Vant UI',
              'Animate.css',
            ]}
          />
        </TaskWrapper>
        <TaskWrapper>
          <TaskTitle>
            사내 기술 블로그 구축
          </TaskTitle>
          <TaskDate>
            2022년 01월 ~ 2022년 01월
          </TaskDate>
          <TaskSubTitle>
            Description
          </TaskSubTitle>
          <TaskContent>
            구성원들의 기술 역량 증진 및 채용 브랜딩을 위해 기술 블로그를 구축하였습니다.
            <br />
            <Link href="https://univdev.page/posts/product-tech-blog-of-company/">
              기술 블로그 구현기 보러가기
            </Link>
          </TaskContent>
          <TaskSubTitle>
            What I did
          </TaskSubTitle>
          <TaskList
            items={[
              'Jekyll 환경 구축',
              'Gitlab Pages 연동',
            ]}
          />
        </TaskWrapper>
      </RightColumn>
    </Row>
  );
}
