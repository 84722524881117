import clsx from 'clsx';
import { css } from '@emotion/react';
import { LeftColumnProps } from './LeftColumn.types';

export function LeftColumn({
  children,
  className,
  style,
}: LeftColumnProps) {
  return (
    <div
      className={clsx(
        'Row__LeftColumn',
        className,
      )}
      style={{
        ...style,
      }}
      css={css`
        position: relative;
        flex-basis: 18rem;
        padding-right: 1rem;
        align-self: stretch;
        @media screen and (max-width: 960px) {
          width: 100%;
          padding-bottom: 1.5rem;
        }
      `}
    >
      { children }
    </div>
  );
}
