import { CSSProperties } from 'react';
import { useScrollProgress } from './useScrollProgress';
import { PRIMARY_COLOR_01 } from '../../constants/colors';

export function Progress() {
  const { percentage } = useScrollProgress();

  return (
    <div
      className="Progress"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 8,
        pointerEvents: 'none',
      } as CSSProperties}
      data-value={percentage}
    >
      <div
        className="Progress__Child"
        style={{
          width: `${percentage * 100}%`,
          height: '100%',
          backgroundColor: PRIMARY_COLOR_01,
        }}
      />
    </div>
  );
}
