import { useContext } from 'react';
import { ParticleContext } from './ParticleProvider';

export const useParticle = () => {
  const context = useContext(ParticleContext);

  if (context === undefined) throw new Error('ParticleContext is undefined.');

  return context;
};
