import { css } from '@emotion/react';
import { RowProps } from './Row.types';

export function Row({
  children,
  id,
}: RowProps) {
  return (
    <div
      id={id}
      className="Row"
      css={css`
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 3rem 0;
        border-bottom: 1px solid #EEE;
        flex-basis: 1;
        &:last-of-type {
          border-bottom: none;
        }
      `}
    >
      { children }
    </div>
  );
}
