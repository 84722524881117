import { ExperienceProps } from './Experience.types';
import { ExperienceCaption } from './ExperienceCaption';
import { ExperienceDate } from './ExperienceDate';
import { ExperienceTitle } from './ExperienceTitle';
import { ExperienceWrapper } from './ExperienceWrapper';

export function Experience({
  caption,
  date,
  title,
}: ExperienceProps) {
  return (
    <ExperienceWrapper>
      <ExperienceTitle>
        { title }
      </ExperienceTitle>
      {
        caption
          && (
          <ExperienceCaption>
            { caption }
          </ExperienceCaption>
          )
      }
      {
        date
          && (
            <ExperienceDate>
              { date }
            </ExperienceDate>
          )
      }
    </ExperienceWrapper>
  );
}
