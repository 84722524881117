import { css } from '@emotion/react';
import { TaskWrapperProps } from './TaskWrapper.types';

export function TaskWrapper({
  children,
}: TaskWrapperProps) {
  return (
    <div
      className="TaskWrapper"
      css={css`
        padding: 1.5rem 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      `}
    >
      { children }
    </div>
  );
}
