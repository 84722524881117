import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

export const useScrollProgress = () => {
  const [documentCurrentTop, setDocumentCurrentTop] = useState(0);
  const [documentScrollHeight, setDocumentScrollHeight] = useState(0);
  const [documentClientHeight, setDocumentClientHeight] = useState(0);
  const percentage = useMemo(() => {
    if (documentScrollHeight === 0 || documentClientHeight === 0) return 1;
    if (documentScrollHeight === documentClientHeight) return 1;

    return documentCurrentTop / (documentScrollHeight - documentClientHeight);
  }, [
    documentCurrentTop,
    documentScrollHeight,
    documentClientHeight,
  ]);

  const setScrollStates = useCallback(() => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
    } = document.documentElement;

    setDocumentCurrentTop(scrollTop);
    setDocumentScrollHeight(scrollHeight);
    setDocumentClientHeight(clientHeight);
  }, []);

  useEffect(() => {
    setScrollStates();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setScrollStates);
    window.addEventListener('scroll', setScrollStates);

    return () => {
      window.addEventListener('resize', setScrollStates);
      window.removeEventListener('scroll', setScrollStates);
    };
  }, []);

  return {
    percentage,
  };
};
