import { css, keyframes } from '@emotion/react';
import { PRIMARY_COLOR_01 } from '../../constants/colors';

export function Cursor() {
  const blink = keyframes`
    0%, 50% {
      opacity: 0;
    }

    51%, 100% {
      opacity: 1;
    }
  `;

  return (
    <span
      className="Cursor"
      css={css`
        display: inline-block;
        width: 20px;
        height: 8px;
        background-color: ${PRIMARY_COLOR_01};
        margin-left: 8px;
        margin-bottom: -8px;
        animation: ${blink} 1s infinite;
      `}
    />
  );
}
