import { useEffect } from 'react';
import { UseScrollDetectorArguments } from './useScrollDetector.types';

export const useScrollDetector = ({
  element,
  onDetect,
  onUndetect,
}: UseScrollDetectorArguments) => {
  useEffect(() => {
    if (element && onDetect) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) onDetect?.();
          if (!isIntersecting) onUndetect?.();
        });
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
  }, [
    element,
    onDetect,
  ]);
};
