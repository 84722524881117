import {
  Fragment,
  PropsWithChildren, createContext, useCallback, useMemo, useRef, useState,
} from 'react';
import { PARTICLE, ParticleContextArguments } from './ParticleProvider.types';
import { ParticleMapItem, ParticleOptions } from './types';
import { FIREWORKS_PARTICLE_NAME } from './constants';
import { Fireworks } from './Fireworks';

export const ParticleContext = createContext<ParticleContextArguments | undefined>(undefined);

export function ParticleProvider({
  children,
}: PropsWithChildren) {
  const index = useRef(0);
  const [particleMap, setParticleMap] = useState<Map<number, ParticleMapItem>>(new Map());

  const show = useCallback((particle: PARTICLE, options: ParticleOptions) => {
    const clone = new Map(particleMap);
    const currentIndex = index.current;

    clone.set(
      currentIndex,
      {
        type: particle,
        options,
      },
    );

    setParticleMap(clone);

    index.current += 1;

    return currentIndex;
  }, [
    particleMap,
  ]);

  const hide = useCallback((particleId: number) => {
    const clone = new Map(particleMap);
    const hasItem = clone.has(particleId);
    clone.delete(particleId);

    setParticleMap(clone);

    return Boolean(hasItem);
  }, []);

  const contextValue = useMemo(() => {
    return {
      show,
      hide,
    };
  }, [
    show,
    hide,
  ]);

  return (
    <ParticleContext.Provider value={contextValue}>
      { children }
      {
        Array.from(particleMap).map(([particleIndex, { type, options }]) => {
          return (
            <Fragment
              key={particleIndex}
            >
              {
                type === FIREWORKS_PARTICLE_NAME && (
                  <Fireworks
                    index={particleIndex}
                    options={options}
                  />
                )
              }
            </Fragment>
          );
        })
      }
    </ParticleContext.Provider>
  );
}
