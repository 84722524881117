import { DangamTasks } from './_comp/DangamTasks';
import { FireworkTrigger } from './_comp/FireworkTrigger';
import { FloatingMailButton, FloatingMailButtonProvider, FloatingMailButtonTrigger } from './_comp/FloatingMailButton';
import { Others } from './_comp/Others';
import { PixoTasks } from './_comp/PixoTasks';
import { StarshellTasks } from './_comp/StarshellTasks';
import { Container } from './components/Container';
import { Cursor } from './components/Cursor';
import { Dot } from './components/Dot';
import { ParticleProvider } from './components/Particle';
import { Progress } from './components/Progress';
import { Section } from './components/Section';
import { SectionTitle } from './components/SectionTitle/SectionTitle';
import { SubTitle } from './components/SubTitle';
import { Title } from './components/Title';
import { TitleSection } from './components/TitleSection';

export function App() {
  return (
    <FloatingMailButtonProvider>
      <ParticleProvider>
        <Progress />
        <Container>
          <TitleSection>
            <Title>
              FE 개발자
              <br />
              박찬영입니다
              <Dot />
              <Cursor />
            </Title>
            <SubTitle>
              6년차 프론트엔드 개발자입니다. 6인으로 구성 된 소규모팀을 이끌어본 경험이 있고 스타트업부터 대기업까지
              다양한 규모의 회사들과 협업하여 서비스를 성공적으로 런칭 시킨 경험이 있습니다.
              현재는 픽소에서 글로벌 시장을 타겟으로한 로고 제작 서비스를 제작하여 고도화 작업을 진행 중입니다.
              <br />
              React와 Vue를 ❤️ 합니다.
              <br />
            </SubTitle>
          </TitleSection>
          <Section>
            <SectionTitle>
              Experience
            </SectionTitle>
            <PixoTasks />
            <DangamTasks />
            <StarshellTasks />
          </Section>
          <Others />
          <FloatingMailButtonTrigger />
          <FireworkTrigger />
        </Container>
        <FloatingMailButton />
      </ParticleProvider>
    </FloatingMailButtonProvider>
  );
}
