import { PropsWithChildren } from 'react';

export function SubTitle({
  children,
}: PropsWithChildren) {
  return (
    <p
      className="SubTitle"
      style={{
        fontSize: '2rem',
        lineHeight: 1.6,
        fontWeight: 500,
      }}
    >
      { children }
    </p>
  );
}
