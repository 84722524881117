import { PropsWithChildren } from 'react';
import { Dot } from '../Dot';

export function OtherSubTitle({
  children,
}: PropsWithChildren) {
  return (
    <h5
      className="OtherSubTitle"
      style={{
        fontSize: '1.3rem',
        margin: '0 0 .5rem',
        color: '#333',
      }}
    >
      { children }
      <Dot />
    </h5>
  );
}
