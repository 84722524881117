import {
  useCallback, useEffect, useState,
} from 'react';
import { Canvas } from './Canvas';
import { useParticle } from './useParticle';
import { ParticleComponentProps } from './types';
import { useConfetti } from './useConfetti';
import { randomInRange } from '../../utils';

export function Fireworks({
  options,
  index,
}: ParticleComponentProps) {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>();
  const { hide } = useParticle();
  const FIREWORKS_SAFE_END_TIME = 3500;
  const endTime = options.duration + FIREWORKS_SAFE_END_TIME;

  const generateEffect = useCallback(() => {
    return [
      {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: randomInRange(40, 70),
        origin: {
          x: randomInRange(0.1, 0.3),
          y: Math.random() - 0.2,
        },
      },
      {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: randomInRange(40, 70),
        origin: {
          x: randomInRange(0.7, 0.9),
          y: Math.random() - 0.2,
        },
      },
    ];
  }, []);

  useConfetti({
    element: canvasElement || undefined,
    interval: 300,
    duration: options.duration,
    effectCallback: generateEffect,
  });

  const end = useCallback(() => {
    hide(index);
  }, [
    index,
    hide,
  ]);

  useEffect(() => {
    const interval = window.setTimeout(end, endTime);

    return () => {
      window.clearInterval(interval);
    };
  }, [
    end,
    endTime,
  ]);

  return (
    <Canvas
      ref={(element) => {
        if (element) setCanvasElement(element);
      }}
    />
  );
}
