import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function OtherDate({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="OtherDate"
      css={css`
        padding: .2rem 0;
      `}
    >
      { children }
    </div>
  );
}
