import clsx from 'clsx';
import { SectionTitleProps } from './SectionTitle.types';
import { Dot } from '../Dot';

export function SectionTitle({
  className,
  children,
  style,
}: SectionTitleProps) {
  return (
    <h2
      className={clsx(
        'SectionTitle',
        className,
      )}
      style={{
        margin: '4rem 0 2rem',
        fontSize: '3rem',
        ...style,
      }}
    >
      { children }
      <Dot />
    </h2>
  );
}
