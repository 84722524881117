import { css } from '@emotion/react';
import { OtherTitleProps } from './OtherTitle.types';
import { Dot } from '../Dot';

export function OtherTitle({
  children,
}: OtherTitleProps) {
  return (
    <h3
      className="OtherTitle"
      css={css`
        font-size: 2rem;
        margin: 0 0 1rem;
      `}
    >
      { children }
      <Dot />
    </h3>
  );
}
