import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function OtherContent({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="OtherContent"
      css={css`
        margin-bottom: 1rem;
      `}
    >
      { children }
    </div>
  );
}
