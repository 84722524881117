import { css } from '@emotion/react';
import { OtherWrapperProps } from './OtherWrapper.types';

export function OtherWrapper({
  children,
}: OtherWrapperProps) {
  return (
    <div
      className="OtherWrapper"
      css={css`
        padding: 3rem 0;
        border-bottom: 1px solid #EEE;
        &:last-of-type {
          border-bottom: none;
        }
      `}
    >
      { children }
    </div>
  );
}
