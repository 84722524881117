import { css } from '@emotion/react';
import { forwardRef } from 'react';

export const Canvas = forwardRef<HTMLCanvasElement, unknown>((_, ref) => {
  return (
    <canvas
      ref={ref}
      className="ConfettiCanvas"
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      `}
    />
  );
});
