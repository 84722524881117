import { AnimatePresence, motion } from 'framer-motion';
import { css } from '@emotion/react';
import { MailButton } from '../../components/MailButton';
import { useFloatingMailButton } from './useFloatingMailButton';

export function FloatingMailButton() {
  const { isShow } = useFloatingMailButton();

  return (
    <AnimatePresence>
      {
        isShow
          && (
            <motion.div
              transition={{
                duration: 0.5,
              }}
              initial={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                translateY: '100%',
              }}
              animate={{
                translateY: 0,
              }}
              exit={{
                translateY: '100%',
              }}
              css={css`
                padding: 20px;
              `}
            >
              <MailButton
                css={css`
                  box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
                `}
              />
            </motion.div>
          )
      }
    </AnimatePresence>
  );
}
