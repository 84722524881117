import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';
import { Dot } from '../Dot';

export function ExperienceTitle({
  children,
}: PropsWithChildren) {
  return (
    <h4
      className="ExperienceTitle"
      css={css`
        font-size: 2rem;
        padding: 0 0 1rem;
      `}
    >
      { children }
      <Dot />
    </h4>
  );
}
