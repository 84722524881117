import clsx from 'clsx';
import { TitleSectionProps } from './TitleSection.types';

export function TitleSection({
  children,
  className,
  style,
}: TitleSectionProps) {
  return (
    <section
      className={clsx(
        'TitleSection',
        className,
      )}
      style={{
        padding: '0 2rem',
        ...style,
      }}
    >
      { children }
    </section>
  );
}
