import { Link } from '../../components/Link';
import { OtherSubTitle } from '../../components/Other';
import { Other } from '../../components/Other/Other';
import { OtherContent } from '../../components/Other/OtherContent';
import { OtherList } from '../../components/Other/OtherList';
import { Section } from '../../components/Section';
import { SectionTitle } from '../../components/SectionTitle/SectionTitle';

export function Others() {
  return (
    <Section>
      <SectionTitle>
        Others
      </SectionTitle>
      <Other
        title="Tech Blog"
        date="꾸준히 작성중!"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <Link href="https://univdev.page">기술 블로그</Link>
        를 운영하고 있습니다. 기술 블로그에는 트러블 슈팅 경험, 신기술 동향 등 다양한 기술 관련 포스트를 비정기적으로 업로드 합니다.
        Github Page에서 정적 페이지로 동작하며 Jekyll과 Chirpy 테마로 구축 되었습니다.
      </Other>
      <Other
        title="인평자동차고등학교 프로그래밍 강사"
        date="2020.05 ~ 2020.09"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <OtherContent>
          인평자동차고등학교에서 Javascript와 PHP의 기초 수준 강의를 진행하였습니다.
        </OtherContent>
        <OtherSubTitle>
          What I did
        </OtherSubTitle>
        <OtherList
          items={[
            'PHP + MySQL 프로젝트 연동 방법',
            'CRUD 연습용 게시판 프로젝트 제작',
            'Javascript + Canvas를 이용한 이미지 편집 툴 제작해보기',
            '2019 지방기능경기대회 모의과제 풀어보기',
          ]}
        />
      </Other>
      <Other
        title="세명컴퓨터고등학교 프로그래밍 강사"
        date="2017.05 ~ 2017.12"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <OtherContent>
          세명컴퓨터고등학교 기능경기대회 준비반 학생들과 인연이 닿게 되어 1, 2학기 프로그래밍 강사로 활동하였습니다.
          기능경기대회 관련 조언과 함께 이전 년도 출제 문제들을 풀어보았습니다.
          공식적인 지식 공유 활동을 해본 것은 이번이 처음이라 많이 어설펐지만 다음 기회가 주어진다면 이 경험을 토대로
          강의를 어떻게 진행 해야 할지 알게 된 계기가 되었습니다.
        </OtherContent>
        <OtherSubTitle>
          What I did
        </OtherSubTitle>
        <OtherList
          items={[
            '기능경기대회 기출문제 풀어보기',
            '국제기능경기대회 과제 분석 해보기',
          ]}
        />
      </Other>
      <Other
        title="제 51회 전국기능경기대회 웹디자인 및 개발 부문"
        caption="동메달 수상"
        date="2016.08"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <OtherContent>
          2016년에 개최 된 경기도 전국기능경기대회 &apos;웹디자인 및 개발&apos; 부문에서 안산공고 소속으로 출전해 동메달을 수상하였습니다.
          웹디자인 및 개발 종목은 웹 디자인, 퍼블리싱, Javascript 코딩, PHP 코딩 총 4가지 과제로 각각 3시간씩 총 12시간 진행 됩니다.
          각각의 과제마다 요구사항이 기재 되어 있어서 해당 조건을 부합하면서 더 좋은 UX를 만들어 낸 선수가 고득점을 받게 됩니다.
          생각 했던 것 만큼의 성과는 얻지 못해 아쉽지만 진심으로 노력하여 큰 성과를 이루어 냈다는 뜻깊은 경험을 할 수 있었습니다.
        </OtherContent>
        <OtherSubTitle>
          News
        </OtherSubTitle>
        <OtherContent>
          <Link
            href="http://www.ansansm.co.kr/news/articleView.html?idxno=31675"
          >
            안산신문 - 기능교육의 달인 ‘안산공고’
          </Link>
        </OtherContent>
      </Other>
      <Other
        title="제 51회 경기도 지방기능경기대회 웹디자인 및 개발 부문"
        caption="은메달 수상"
        date="2016.04"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <OtherContent>
          2016년에 개최 된 지방기능경기대회 &apos;웹디자인 및 개발&apos; 부문에서 안산공고 소속으로 출전해 은메달을 수상하였습니다.
          웹디자인 및 개발 종목은 웹 디자인, 퍼블리싱, Javascript 코딩, PHP 코딩 총 4가지 과제로 각각 3시간씩 총 12시간 진행 됩니다.
          지방기능경기대회에서 입상하면 당해 하반기에 개최 되는 전국기능경기대회 출전권을 얻게 되기에 저와 친구들은 부단히 노력하였고,
          마침내 함께 노력한 친구들 모두가 나란히 입상하는 뜻깊은 성과를 얻었습니다.
        </OtherContent>
        <OtherSubTitle>
          News
        </OtherSubTitle>
        <OtherContent>
          <Link
            href="http://www.ansantimes.co.kr/news/articleView.html?idxno=21002"
          >
            안산타임스 - 안산공고, 기능경기대회 준우승 ‘쾌거’
          </Link>
        </OtherContent>
      </Other>
      <Other
        title="인하공업전문대학"
        caption="컴퓨터시스템과"
        date="2018년 ~ 재학 중"
      >
        <OtherSubTitle>
          Description
        </OtherSubTitle>
        <OtherContent>
          인하공업전문대학에서 컴퓨터시스템을 전공하였습니다.
          Android App 개발, 임베디드 프로그래밍, 컴퓨터 네트워크, 알고리즘과 자료구조 등의 과목을 공부하였습니다.
        </OtherContent>
      </Other>
    </Section>
  );
}
