import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function ExperienceWrapper({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="ExperienceWrapper"
      css={css`
        position: sticky;
        top: 40px;
      `}
    >
      { children }
    </div>
  );
}
