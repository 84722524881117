import { useCallback, useState } from 'react';
import { ScrollDetector } from '../../components/ScrollDetector';
import { useParticle } from '../../components/Particle';
import { FIREWORKS_PARTICLE_NAME } from '../../components/Particle/constants';

export function FireworkTrigger() {
  const { show } = useParticle();
  const [isWorked, setIsWorked] = useState(false);

  const handleDetect = useCallback(() => {
    if (!isWorked) {
      show(FIREWORKS_PARTICLE_NAME, { duration: 7000 });
      setIsWorked(true);
    }
  }, [
    isWorked,
  ]);

  return (
    <ScrollDetector
      threshold={100}
      onDetect={handleDetect}
    />
  );
}
