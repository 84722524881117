import { TaskBoldProps } from './TaskBold.types';

export function TaskBold({
  children,
}: TaskBoldProps) {
  return (
    <b
      className="TaskBold"
      style={{
        display: 'inline-block',
        margin: '.5rem 0',
      }}
    >
      { children }
    </b>
  );
}
