import { Dot } from '../Dot';
import { TaskSubTitleProps } from './TaskSubTitle.types';

export function TaskSubTitle({
  children,
}: TaskSubTitleProps) {
  return (
    <h5
      className="TaskSubTitle"
      style={{
        fontSize: '1.3rem',
        margin: '0 0 .5rem',
        color: '#333',
      }}
    >
      { children }
      <Dot />
    </h5>
  );
}
