import { LinkProps } from './Link.types';

export function Link({
  children,
  href,
  target = '_blank',
}: LinkProps) {
  return (
    <a
      href={href}
      target={target}
      className="Link"
    >
      { children }
    </a>
  );
}
