import { css } from '@emotion/react';
import { OtherListProps } from './OtherList.types';
import { OtherListItem } from './OtherListItem';

export function OtherList({
  items,
}: OtherListProps) {
  return (
    <ul
      className="OtherList"
      css={css`
        font-size: 1.1rem;
        margin: 0 0 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      `}
    >
      {
        items.map((item) => {
          return (
            <OtherListItem
              key={Math.random()}
            >
              { item }
            </OtherListItem>
          );
        })
      }
    </ul>
  );
}
