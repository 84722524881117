import { css } from '@emotion/react';
import { PRIMARY_COLOR_01 } from '../../constants/colors';

export function Dot() {
  return (
    <span
      className="Dot"
      style={{
        display: 'inline-block',
        marginLeft: 2,
        color: PRIMARY_COLOR_01,
      }}
      css={css`
        display: none;
      `}
    >
      .
    </span>
  );
}
