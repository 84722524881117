import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export function ExperienceDate({
  children,
}: PropsWithChildren) {
  return (
    <div
      className="ExperienceDate"
      css={css`
        font-size: 16px;
        margin: 0;
        color: '#222222';
      `}
    >
      { children }
    </div>
  );
}
