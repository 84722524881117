import { useState } from 'react';
import { useScrollDetector } from './useScrollDetector';
import { ScrollDetectorProps } from './ScrollDetector.types';

export function ScrollDetector({
  threshold = 150,
  onDetect,
  onUndetect,
}: ScrollDetectorProps) {
  const [triggerElement, setTriggerElement] = useState<HTMLDivElement | undefined>(undefined);

  useScrollDetector({
    element: triggerElement,
    onDetect,
    onUndetect,
  });

  return (
    <div
      className="ScrollDetector"
      style={{
        position: 'relative',
        pointerEvents: 'none',
      }}
    >
      <div
        ref={(element) => {
          if (element) setTriggerElement(element);
        }}
        className="ScrollDetector__Trigger"
        style={{
          position: 'absolute',
          left: '50%',
          bottom: 0,
          transform: 'translateX(-50%)',
          opacity: 0,
          width: 1,
          height: threshold,
        }}
      />
    </div>
  );
}
